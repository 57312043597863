
import React from 'react';
import { Link } from 'gatsby';
import Toggle from './Toggle';
import Helmet from 'react-helmet';
import logo from '../assets/DIY_HeatPump_Logo.svg';
import Footer from '../components/Footer';
import sun from '../assets/sun.png';
import moon from '../assets/moon.png';
import './Layout.scss';

class Layout extends React.Component {
  state = {
    theme: null,
  };
  componentDidMount() {
    this.setState({ theme: window.__theme });
    window.__onThemeChange = () => {
      this.setState({ theme: window.__theme });
    };
  }
  render() {
    const { children } = this.props;

    return (
      <div
        style={{
          color: 'var(--textNormal)',
          background: 'var(--bg)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        <Helmet
          meta={[
            {
              name: 'theme-color',
              content: this.state.theme === 'light' ? '#ffa8c5' : '#282c35',
            },
          ]}
        />
        <header className="main-header">
          <Link aria-current="page" aria-label='logo' to="/" className="main-logo-link ugly-link" href="/">
            <img
              src={ logo } alt=""
            />
          </Link>
          <nav className="main-nav">
            {[
              {
                href: '/',
                text: 'Episodes',
              },
              {
                href: '/contact/',
                text: 'Contact',
              },
              {
                href: '/about/',
                text: 'About',
              },
              {
                href: 'https://www.patreon.com/diyheatpump/',
                text: 'Patreon',
              },
            ].map(page => {
              const isExternal = page.href.match(/^http/);
              const Component = isExternal ? 'a' : Link;
              const props = { [isExternal ? 'href' : 'to']: page.href };
              return (
                <Component
                  className="main-nav-link"
                  key={page.href}
                  {...props}
                >
                  {page.text}
                </Component>
              );
            })}
          </nav>
          {this.state.theme !== null ? (
            <Toggle
              icons={{
                checked: (
                  <img
                    src={moon}
                    width="16"
                    height="16"
                    role="presentation"
                    style={{ pointerEvents: 'none' }}
                  />
                ),
                unchecked: (
                  <img
                    src={sun}
                    width="16"
                    height="16"
                    role="presentation"
                    style={{ pointerEvents: 'none' }}
                  />
                ),
              }}
              checked={this.state.theme === 'dark'}
              onChange={e =>
                window.__setPreferredTheme(
                  e.target.checked ? 'dark' : 'light'
                )
              }
            />
          ) : (
            <div style={{ height: '24px' }} />
          )}
        </header>
        <main>
          <div className='decorative-shit'></div>
          <div className="main-content-wrapper">
            {children}
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}

export default Layout;
