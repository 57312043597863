/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultOpenGraphImage from '../../static/diyheatpump_image.webp'
import SchemaOrg from './SchemaOrg';

function SEO({ description, lang, meta, title, image, isBlogPost, frontmatter = {}, slug }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            social {
              twitter
            }
            organization {
              name
              url
              logo {
                url
                width
                height
              }
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  // const ogImageUrl = image || site.siteMetadata.siteUrl +  defaultOpenGraphImage;
  const ogImageUrl = site.siteMetadata.siteUrl + (image || defaultOpenGraphImage)
  const url = slug
        ? `${site.siteMetadata.siteUrl}${slug}`
        : site.siteMetadata.siteUrl;
  const datePublished = isBlogPost ? frontmatter.datePublished : false;
  const dateModified = isBlogPost ? frontmatter.dateModified : false;
  const ogTypeContent = isBlogPost ? `article` : `website`;



  return (
    <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      
      meta={[
        // ------General Tags--------
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
        // ---------Twitter Card Tags---------
        {
          property: `twitter:image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // ---------Open Graph Tags------------
        {
          property: `og:type`,
          content: ogTypeContent,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property:`og:image`,
          content: ogImageUrl,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={url} />
    </Helmet>
    <SchemaOrg
      isBlogPost={isBlogPost}
      url={url}
      title={title}
      image={ogImageUrl}
      description={metaDescription}
      datePublished={datePublished}
      dateModified={dateModified}
      siteUrl={site.siteMetadata.siteUrl}
      author={site.siteMetadata.author}
      organization={site.siteMetadata.organization}
      defaultTitle={site.siteMetadata.title}
    />
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
