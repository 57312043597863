import React from 'react';

import { rhythm } from '../utils/typography';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer
        style={{
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
          background: '#15212a',
        }}
      >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        maxWidth: '1000px',
        margin: '0 auto',
      }}>
        <span>© {new Date().getFullYear()}, Heat Pump DIY</span>
      </div>
      </footer>
    );
  }
}

export default Footer;
